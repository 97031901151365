import { handleQueryResolve } from '../utils'

export default function(PatientID) {
    const attributes = []

    const queryStr = `
  SELECT
  Pat.FirstName,
  Pat.LastName,
  Pat.FirstName + ' ' + Pat.LastName AS PatientName,
  rx.ID,
  MixID,
  rx.DocID,
  Rx.PatID as PatientID,
  DrgMix.Description,
  OrigRxNum,
  RxNum,
  --FillDate,
  CONVERT(DATE,Rx.FillDate,112) AS FillDate,
  DispQty,
  AAC,
  Cost,
  Markup,
  Fee,
  MixTime,
  MixFee,
  Status,
  doctor.FirstName as DoctorFirstName,
  doctor.LastName as DoctorLastName
  --'Pending' as mixStatus
FROM
  pharmacy.dbo.Rx
  INNER JOIN pharmacy.dbo.Pat on rx.PatID = pat.ID
  INNER JOIN pharmacy.dbo.DrgMix ON DrgMix.ID = rx.MixID
  INNER JOIN pharmacy.dbo.Doc as doctor ON doctor.ID = rx.DocID
WHERE
  MixID >= 1 
 AND Rx.PatID = ${PatientID}
 AND Rx.FillDate > DATEADD(month, -12, GetDate())
ORDER BY
  FillDate DESC
      `

    // console.log(queryStr)

    return this.query(queryStr, attributes).then(handleQueryResolve)
}